<template>
  <div class="main">
    <a-layout class="mlayout">
      <div class="topTitle"></div>
      <a-layout-content class="laycontent">
        <div class="breadcrumb">
          <a-breadcrumb>
            <a-breadcrumb-item></a-breadcrumb-item>
          </a-breadcrumb>
        </div>

        <div class="yzdiv">
          <div class="mid">
            <div class="inputdiv" v-show="asteps == 0">
              <a-form :form="form" class="myform">
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label=""
                  style="text-align: center"
                >
                  <h1 style="color: #547bd5; font-size: 24px">...</h1>
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label=""
                >
                  <h3 style="line-height: 24px; margin-bottom: 0px">
                    账户名称
                  </h3>
                  <a-input
                    class="logininput"
                    v-decorator="[
                      'userName',
                      {
                        rules: [
                          { required: true, message: '请填写你的用户名' },
                        ],
                      },
                    ]"
                    oninput="if(value.length > 40)value = value.slice(0, 40)"
                    @input="inputtext"
                    placeholder=""
                    :disabled="loginLoading"
                    style="
                      background-color: rgba(255, 255, 255, 0.2);
                      width: 220px;
                    "
                  >
                    <a-icon
                      slot="prefix"
                      type="user"
                      style="color: rgba(0, 0, 0, 0.25)"
                    />
                  </a-input>
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label=""
                >
                  <h3 style="line-height: 24px; margin-bottom: 0px">
                    输入密码
                  </h3>
                  <a-input-password
                    class="logininput"
                    v-decorator="[
                      'passWord',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请填写你的密码',
                          },
                        ],
                      },
                    ]"
                    oninput="if(value.length > 20)value = value.slice(0, 30)"
                    @input="inputtext"
                    placeholder=""
                    :disabled="loginLoading"
                    style="
                      width: 220px;
                    "
                  >
                    <a-icon
                      slot="prefix"
                      type="lock"
                      style="color: rgba(0, 0, 0, 0.25)"
                    />
                  </a-input-password>
                </a-form-item>
              </a-form>
              <div class="errmessage" style="line-height: 20px">
                <!-- <a-divider type="vertical" /> -->
                <template v-if="errmessage != ''">
                  <a-icon type="exclamation-circle" /> {{ errmessage }}
                  <p>
                    {{ errmessage2 }}
                  </p>
                </template>
              </div>
            </div>
          </div>
          <div class="bottom">
            <a-button
              @click="check"
              type="primary"
              v-if="!nextdisabled"
              :loading="loginLoading"
              style="width: 240px; border: 1px solid #fff"
            >
              {{ buttontext }}
            </a-button>
            <a-button
              @click="check"
              type="primary"
              v-if="nextdisabled"
              disabled
              style="width: 240px; border: 1px solid #fff"
            >
              {{ buttontext }}
            </a-button>

            <!-- <a-button type="primary" @click="nextstep"> 下一步 </a-button> -->
          </div>
        </div>

        <div class="footerdiv">
          <footer-bar />
        </div>
      </a-layout-content>
    </a-layout>

    <a-modal
      :width="800"
      :title="'公告'"
      :visible="visibleModalGG"
      ok-text="确定"
      cancel-text=""
      @ok="handleGG"
      @cancel="handleGG"
    >
      <div>
        <h3>:</h3>

        <p style="font-size: 14px; line-height: 20px">
          <a-row style="margin-bottom: 10px">
            <a-col :span="1"><span style="font-size: 18px"></span></a-col>
            <a-col :span="22"></a-col>
          </a-row>
        </p>
        <br />
        <h3></h3>
        <p style="font-size: 14px; line-height: 26px">
          <a-row style="margin-bottom: 10px">
            <a-col :span="1"><span style="font-size: 18px"></span></a-col>
            <a-col :span="22"></a-col>
          </a-row>
        </p>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleGG"> 关闭 </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { login } from "../api/index";
import TopBar from "../components/TopBar.vue";
import FooterBar from "../components/FooterBar.vue";
import global from "../common";
const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};
const formTailLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 10, offset: 6 },
};
export default {
  name: "login",
  components: {
    TopBar,
    FooterBar,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      asteps: 0,
      userName: "",
      passWord: "",
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),

      nextdisabled: false,
      buttontext: "安全登录",

      userinfo: {},

      errmessage: "",

      errmessage2: "",

      loginLoading: false,

      visibleModalGG: false,
    };
  },
  created() {
    console.log("login_name:", global.getlocalStorage("login_name"));
    let that = this;
    setTimeout(() => {
      that.form.setFieldsValue({
        userName: global.getlocalStorage("login_name"),
        passWord: "",
      });
    });

    var isChrome = window.navigator.userAgent.indexOf("Chrome") !== -1;
    if (isChrome) {
        // alert("是Chrome浏览器");
    } else {
        // alert("您使用的不是Chrome浏览器");
    }

  },
  methods: {
    handleGG() {
      this.visibleModalGG = false;
    },
    inputtext() {
      this.errmessage = "";
      this.errmessage2 = "";
    },
    check() {
      let that = this;
      let asteps = this.asteps;

      this.errmessage = "";
      this.errmessage2 = "";

      if (asteps == 0) {
        this.form.validateFields((err, values) => {
          console.log("Received values of form: ", values);
          that.userinfo = values;
          if (!err) {
            console.info("success");
            this.loginLoading = true;
            login({
              username: that.userinfo.userName,
              password: that.userinfo.passWord,
            }).then((res) => {
              console.log("login:", res);
              let results = res.results;
              if (results.message == "1") {
                global.setLoginInfo(
                  true,
                  results.username,
                  results.realname,
                  results.uid,
                  results.level,
                  results.authkeys,
                  results.authrules,
                  results.city,
                  results.citycode,
                  results.dis,
                  results.discode,
                  results.unid,
                  results.unname,
                  results.photourl
                );
                // console.log("login state:",global.getLoginA());
                global.setlocalStorage("login_name", results.username);
                that.errmessage = "";
                that.buttontext = "登录成功";
                setTimeout(() => {
                  that.loginLoading = false;
                  // that.$router.push("/basemag");
                  that.$router.push("/casemag/00");
                }, 1500);
              } else {
                that.loginLoading = false;
                that.errmessage = "用户名或密码错误";
                if (results.errorcount >= 5 && results.errorcountf > -1) {
                  that.errmessage = "密码错误,连续超过5次，已锁定。";
                  // that.errmessage2 = "将于 " + results.unlocktime + " 解锁";
                  that.errmessage2 = "联系企业管理员解锁";
                } else if (results.errorcount > 0 && results.errorcountf > -1) {
                  that.errmessage =
                    "登录错误,剩余次数:" + (5 - results.errorcount) + "";
                }
              }
            });
          }
        });
      }
    },
    handleChange(e) {
      this.checkNick = e.target.checked;
      this.$nextTick(() => {
        this.form.validateFields(["nickname"], { force: true });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.myform {
  width: 240px;
}
.footerdiv {
  position: absolute;
  bottom: 2%;
  color: #fff !important;
}
.laycontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photodiv {
  width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 42px;
  background-color: #f6f6f6;
}

.photodiv #video {
  position: relative;
  z-index: 9;
  width: 300px;
  height: 200px;
  object-fit: cover;
  background-color: #000;
}
.photodiv #canvas {
  position: absolute;
  z-index: 0;
  width: 100px;
  height: 66px;
}

.success .title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  color: #52c41a;
}
.success .text {
  color: #333;
  font-size: 12px;
}

.checkuserinfo {
  width: 320px;
  padding: 10px;
  font-size: 12px;
  background-color: #f6f6f6;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 24px;
}
.checkuserinfo .cutitle {
  font-size: 14px;
  font-weight: 600;
}

.loading {
  position: absolute;
  z-index: 99;
  display: inline-block;
  opacity: 1;
  transform: scale(1.5);
}

.errmessage {
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: left;
  color: #666;
  font-size: 14px;
}
.myform{
  width:240px !important;
}
</style>
